import SupabaseTable from './SupabaseTable';
import MenuItems from './MenuItems';

function App() {
  return (
    <div>
      <h1 align="center">Denver Social Club</h1>
      <SupabaseTable />
    </div>
  );
}

export default App;
